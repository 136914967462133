import { Box, useTheme } from "@mui/material";

import { ReactComponent as AWSLightSvg } from "components/icons/awsLight.svg";
import { ReactComponent as AWSDarkSvg } from "components/icons/awsDark.svg";
import { ReactComponent as AWSCostSvg } from "components/icons/awsCost.svg";
import { ReactComponent as GCPCostSvg } from "components/icons/gcpCost.svg";
import { ReactComponent as GCPLightSvg } from "components/icons/gcpLight.svg";
import { ReactComponent as GCPDarkSvg } from "components/icons/gcpDark.svg";
import { ReactComponent as GCPSmallSvg } from "components/icons/gcpSmall.svg";
import { ReactComponent as GithubLightSvg } from "components/icons/githubLight.svg";
import { ReactComponent as GithubDarkSvg } from "components/icons/githubDark.svg";
import { ReactComponent as GoogleSvg } from "components/icons/google.svg";
import { ReactComponent as MicrosoftSvg } from "components/icons/microsoft.svg";
import { ReactComponent as OktaSvg } from "components/icons/okta.svg";
import { ReactComponent as ArrowElementSvg } from "components/icons/arrow.svg";
import { ReactComponent as CheckboxSvg } from "components/icons/checkbox.svg";
import { ReactComponent as FilterTrashElementSvg } from "components/icons/filterTrash.svg";
import { ReactComponent as OpenIssuesSvg } from "components/icons/openIssues.svg";
import { ReactComponent as ResolvedIssuesSvg } from "components/icons/resolvedIssues.svg";
import { ReactComponent as JustifiedIssuesSvg } from "components/icons/justifiedIssues.svg";
import { ReactComponent as SlackSvg } from "components/icons/slack.svg";
import { ReactComponent as JiraSvg } from "components/icons/jira.svg";
import { ReactComponent as ApiKeySvg } from "components/icons/apiKey.svg";
import { ReactComponent as EmailDarkSvg } from "components/icons/emailDark.svg";
import { ReactComponent as EmailLightSvg } from "components/icons/emailLight.svg";
import { ReactComponent as TerraformDarkSvg } from "components/icons/terraformDark.svg";
import { ReactComponent as TerraformLightSvg } from "components/icons/terraformLight.svg";
import { ReactComponent as HttpDarkSvg } from "components/icons/httpDark.svg";
import { ReactComponent as HttpLightSvg } from "components/icons/httpLight.svg";
import { ReactComponent as CongratulationsSvg } from "components/icons/congratulations.svg";
import { ReactComponent as FullScreenElementSvg } from "components/icons/fullScreen.svg";
import { ReactComponent as WidgetElementSvg } from "components/icons/widget.svg";
import { ReactComponent as FilterElementSvg } from "components/icons/filter.svg";
import { ReactComponent as CalendarSvg } from "components/icons/calendar.svg";
import { ReactComponent as DashboardSvg } from "components/icons/dashboard.svg";
import { ReactComponent as NewDashboardSvg } from "components/icons/newDashboard.svg";
import { ReactComponent as MainDashboardSvg } from "components/icons/mainDashboard.svg";
import { ReactComponent as NotMainDashboardSvg } from "components/icons/notMainDashboard.svg";
import { ReactComponent as OrganizationSvg } from "components/icons/organization.svg";
import {
  darkTextPrimaryColor,
  lightTextPrimaryColor,
  lightTextSecondaryDarkColor,
  primaryMainDark,
  primaryMainLight,
} from "theme/commonVariables";

type SvgProps = { height?: number };

const AWSSvg = (props: SvgProps) => {
  const theme = useTheme();

  return theme.palette.mode === "light" ? <AWSLightSvg {...props} /> : <AWSDarkSvg {...props} />;
};

const GCPSvg = (props: SvgProps) => {
  const theme = useTheme();

  return theme.palette.mode === "light" ? <GCPLightSvg {...props} /> : <GCPDarkSvg {...props} />;
};

const GithubSvg = () => {
  const theme = useTheme();

  return theme.palette.mode === "light" ? <GithubLightSvg /> : <GithubDarkSvg />;
};

const HttpSvg = () => {
  const theme = useTheme();

  return theme.palette.mode === "light" ? <HttpLightSvg /> : <HttpDarkSvg />;
};

const TerraformSvg = () => {
  const theme = useTheme();

  return theme.palette.mode === "light" ? <TerraformLightSvg /> : <TerraformDarkSvg />;
};

const EmailSvg = () => {
  const theme = useTheme();

  return theme.palette.mode === "light" ? <EmailLightSvg /> : <EmailDarkSvg />;
};

const FullScreenSvg = ({ mainColor, color }: { mainColor?: boolean; color?: string }) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      sx={{
        svg: {
          path: {
            fill:
              color ||
              (theme.palette.mode === "light"
                ? mainColor
                  ? primaryMainLight
                  : lightTextPrimaryColor
                : mainColor
                ? primaryMainDark
                : darkTextPrimaryColor),
          },
        },
      }}
    >
      <FullScreenElementSvg />
    </Box>
  );
};

const ArrowSvg = () => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      alignItems="center"
      height={1}
      sx={{
        svg: {
          fill: theme.palette.mode === "light" ? lightTextPrimaryColor : darkTextPrimaryColor,
          cursor: "pointer",
        },
      }}
    >
      <ArrowElementSvg />
    </Box>
  );
};

const WidgetSvg = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        svg: {
          path: {
            fill: theme.palette.mode === "light" ? "#4267C7" : "#F9F9F9",
          },
          cursor: "pointer",
        },
      }}
    >
      <WidgetElementSvg />
    </Box>
  );
};

const FilterSvg = ({ open }: { open: boolean }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        svg: {
          path: {
            fill: open && theme.palette.mode === "light" ? lightTextSecondaryDarkColor : "#fff",
          },
          cursor: "pointer",
        },
      }}
    >
      <FilterElementSvg />
    </Box>
  );
};

const FilterTrashSvg = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        svg: {
          ...(theme.palette.mode === "dark" && {
            path: {
              fill: "#2F2B5B",
            },
          }),
          cursor: "pointer",
        },
      }}
    >
      <FilterTrashElementSvg />
    </Box>
  );
};

export {
  AWSSvg,
  GCPSvg,
  GCPSmallSvg,
  GoogleSvg,
  GithubSvg,
  MicrosoftSvg,
  OktaSvg,
  ArrowSvg,
  CheckboxSvg,
  FilterTrashSvg,
  OpenIssuesSvg,
  ResolvedIssuesSvg,
  JustifiedIssuesSvg,
  SlackSvg,
  JiraSvg,
  EmailSvg,
  TerraformSvg,
  HttpSvg,
  CongratulationsSvg,
  FullScreenSvg,
  WidgetSvg,
  FilterSvg,
  ApiKeySvg,
  AWSCostSvg,
  GCPCostSvg,
  CalendarSvg,
  DashboardSvg,
  NewDashboardSvg,
  MainDashboardSvg,
  NotMainDashboardSvg,
  OrganizationSvg,
};
