import { ReactElement } from "react";

import { CLOUD_PLATFORM } from "store/types/accounts.store.types";

export enum INTEGRATION {
  SLACK = "slack",
  EMAIL = "email",
  JIRA = "jira",
  HTTP = "http",
  GITHUB = "github",
  TERRAFORM = "terraform",
  AWS_COST = "awsCost",
  GCP_COST = "gcpCost",
  API_KEY = "apiKey",
  // EVENT_STREAM = "eventStream",
}

export enum INTEGRATION_ACCESS {
  USER = "user",
  ORGANIZATION = "organization",
}

export enum INTEGRATION_TERRAFORM_TYPE {
  CLOUD = "cloud",
  BUCKET = "bucket",
}

export type Integration = {
  type: INTEGRATION;
  title: string;
  comment?: string;
  icon: ReactElement;
};

export type AddTerraformIntegration = {
  name: string;
  token: string;
  workspaceId: string;
  address: string;
  bucketName: string;
  bucketPath: string;
  provider: CLOUD_PLATFORM;
  type: INTEGRATION_TERRAFORM_TYPE;
};
